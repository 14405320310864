<script setup lang="ts">
/**
 * @file Mobile Drag-to-expand Drawer Modal
 */
import OzOverlay from '@@/library/v4/components/OzOverlay.vue'
import Hammer from 'hammerjs'
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    windowHeight: number
    xHeader?: boolean
    /**
     * To show drawer handle
     */
    xDrawerHandle?: boolean
    /**
     * Non-draggable full size drawer
     */
    fullSize?: boolean
    disableDrag?: boolean
    /**
     * When content is overflow, choose to scroll the whole modal or just the body
     */
    scrollType?: 'body' | 'whole'
    darkMode?: boolean | 'auto'
    /**
     * Mobile preferred start height. If device is too small we self compute this
     */
    preferredTrayStartHeight?: number
    shouldFullSizeCoverScreen?: boolean
    xScrim?: boolean
    /**
     * Decides if scrim is not really there and can be clicked through
     **/
    isScrimSpectral?: boolean
    shouldFadeIn?: boolean
    position?: 'fixed' | 'absolute'
    /**
     * Height of mobile "saved space" at the top that the drawer can never touch
     */
    minTopMargin?: number
    /**
     *  TailwindCSS z-index class.
     *
     *  - `z-modal2` is for all dialogs
     *  - `z-sidepanel` is for surface sidepanels
     */
    zIndexClass?: 'z-modal2' | 'z-sidepanel'
    hideScrollbar?: boolean
    /**
     * Full size drawer that is draggable
     */
    fullSizeDraggable?: boolean
  }>(),
  {
    xHeader: true,
    xDrawerHandle: false,
    fullSize: false,
    disableDrag: false,
    scrollType: 'body',
    darkMode: 'auto',
    preferredTrayStartHeight: undefined,
    shouldFullSizeCoverScreen: false,
    xScrim: true,
    isScrimSpectral: false,
    shouldFadeIn: true,
    position: 'fixed',
    minTopMargin: 0,
    zIndexClass: undefined,
    hideScrollbar: false,
    fullSizeDraggable: false,
  },
)

const emit = defineEmits<{
  (name: 'dragstart'): void
  (name: 'dragging', distance: number): void
  (name: 'dragend', distance: number): void
  (name: 'swipeup', { isAtTopBeforeDragging }: { isAtTopBeforeDragging: boolean }): void
  (name: 'swipedown', { isAtTopBeforeDragging }: { isAtTopBeforeDragging: boolean }): void
  (name: 'scrim-click'): void
  (name: 'scrim-esc'): void
}>()

let hammer

const header = ref<HTMLElement>()
const modalBody = ref<HTMLDivElement>()
const modalBodyContent = ref<HTMLDivElement>()
const modalContainer = ref<HTMLDivElement>()
const modalContainerInner = ref<HTMLDivElement>()
const modalContainerInnerWrapper = ref<HTMLDivElement>()

// Phone
const MAX_TOP_MARGIN = ref(0)
const MIN_TOP_MARGIN = ref(0)
const DRAG_DISTANCE_AT_TOP = ref(0)

const dragDistanceY = ref(0)
const dragDistanceYBeforeStart = ref(0)
const latestVelocityY = ref(0) // for computing end velocity
const latestPanEventTimestamp = ref(0) // for computing end velocity
const draggedToFullSize = ref(props.fullSizeDraggable)
const scrollEnabled = ref(false)
const panEnabled = ref(false)
const marginTop = ref(0)

const updateElement = (): void => {
  nextTick(() => {
    const modalContainerEl = modalContainer.value
    if (!modalContainerEl) return
    marginTop.value = MAX_TOP_MARGIN.value + dragDistanceY.value
    if (marginTop.value === MIN_TOP_MARGIN.value) {
      draggedToFullSize.value = true
    } else {
      draggedToFullSize.value = false
    }

    modalContainerEl.style.marginTop = marginTop.value + 'px'
  })
}

const addTransition = (transitionSpeed: 'slow' | 'fast' = 'slow'): void => {
  const modalContainerEl = modalContainer.value
  if (!modalContainerEl) return
  modalContainerEl.style.transition =
    transitionSpeed === 'slow'
      ? `margin 150ms cubic-bezier(0.4, 0, 0.2, 1)`
      : `margin 50ms cubic-bezier(0.4, 0, 0.2, 1)`
  setTimeout(
    () => {
      modalContainerEl.style.transition = ''
    },
    transitionSpeed === 'slow' ? 150 : 100,
  )
}

const resetElement = (
  position: 'full' | 'top' | 'bottom' | 'out' = 'bottom',
  transitionSpeed: 'slow' | 'fast' = 'slow',
): void => {
  addTransition(transitionSpeed)
  if (position === 'bottom') {
    dragDistanceY.value = 0
    disableScroll()
  } else if (position === 'top') {
    dragDistanceY.value = DRAG_DISTANCE_AT_TOP.value
    enableScroll()
  } else if (position === 'full') {
    dragDistanceY.value = -MAX_TOP_MARGIN.value
    enableScroll()
  } else if (position === 'out') {
    dragDistanceY.value = props.windowHeight
  }
  updateElement()
}

/**
 * Compute end velocity based on panEnd event and the latest panMove event to decide if the user is
 * swiping up/down to expand/contract the tray.
 * Sometimes end event can have the incorrect velocity that make it think that user didn't swipe fast enough.
 */
const computeEndVelocity = ({
  endEvent,
  lastPanEvent,
}: Record<string, { velocity: number; timestamp: number }>): number => {
  const direction = (endEvent.velocity + lastPanEvent.velocity) / Math.abs(endEvent.velocity + lastPanEvent.velocity)
  if (endEvent.timestamp - lastPanEvent.timestamp > 60) {
    return direction * Math.max(Math.abs(lastPanEvent.velocity), endEvent.velocity) * 1000
  }
  return direction * Math.abs(endEvent.velocity) * 1000
}

/* SCROLLING */

const scrollTimeout = ref<number>()
const scrollTop = ref(0)
const touchStartY = ref<number>()
const isDragStartEmittedForScroll = ref(false)

const isScrollingWholeModal = computed((): boolean => {
  return props.scrollType === 'whole'
})

/**
 * When scrolling whole modal (header scroll together with body), innerWrapper is scrolled
 * When only the body is scrolling, modalBody is scrolled
 */
const scrollingElement = computed((): HTMLDivElement | undefined => {
  return isScrollingWholeModal.value ? modalContainerInnerWrapper.value : modalBody.value
})

/**
 * Handler for touchstart event for modal body
 */
const handleTrayScrollStart = (e: TouchEvent): void => {
  touchStartY.value = e.touches[0].clientY
  isDragStartEmittedForScroll.value = false
}

/**
 * Handler for touchmove event for modal body.
 * Here we have the logic to decide if hammerjs should take over the event to pan the modal
 */
const handleTrayScroll = (e: TouchEvent): void => {
  if (!scrollEnabled.value) {
    if (e.cancelable) {
      e.preventDefault()
    }
    return
  }
  if (!isDragStartEmittedForScroll.value) {
    isDragStartEmittedForScroll.value = true
    emit('dragstart')
  }
  const element = scrollingElement.value
  if (!element) return
  if (panEnabled.value && element.scrollTop < 0) {
    e.preventDefault() // scroll more than the top = scroll up -- prevent default so that Hammer can pan.
  } else if (panEnabled.value && element.scrollTop === 0) {
    if (touchStartY.value && touchStartY.value > e.changedTouches[0].clientY + 5) {
      // scroll down -- let it scroll
    } else if (touchStartY.value && touchStartY.value < e.changedTouches[0].clientY - 5) {
      // scroll up -- prevent default so that Hammer can pan.
      if (e.cancelable) {
        e.preventDefault()
      }
    }
  }
  if (scrollTimeout.value) {
    clearTimeout(scrollTimeout.value)
    scrollTimeout.value = undefined
  }
  scrollTimeout.value = setTimeout(() => {
    scrollTop.value = element.scrollTop
    const EPSILON = 5 // on iOS if we scroll to the top, the scroll is bounced back by a little
    if (scrollTop.value <= EPSILON) {
      addPanListener()
    } else {
      removePanListener()
    }
  }, 250) as unknown as number
}

/**
 * Enable scrolling when dragging the modal (instead of moving it -- also need to prevent moving)
 */
const enableScroll = (): void => {
  scrollEnabled.value = true
  if (!scrollingElement.value) return
  scrollingElement.value.style.overflow = 'auto'
}

/**
 * Disable scrolling when dragging the modal (will move it instead -- need to let moving happen)
 */
const disableScroll = (): void => {
  scrollEnabled.value = false
  if (!scrollingElement.value) return
  scrollingElement.value.style.overflow = 'visible'
}

/**
 * DRAGGING (hammerjs events)
 */

const scrollWithPan = ref(false) // see explanation 10 lines below

const handleStartPan = (): void => {
  dragDistanceYBeforeStart.value = dragDistanceY.value
  const element = scrollingElement.value
  if (scrollEnabled.value && element?.scrollTop === 0) {
    scrollWithPan.value = true
  }
  /**
   * Drag start event, can be used to disable interaction to items in content to prevent mishandled interactions
   */
  emit('dragstart')
}

const handlePanMove = (distance: number, velocity: number, timestamp: number): void => {
  if (scrollWithPan.value && distance < 0) {
    // Rarely happen:
    // scrolling is enabled but default-prevented and user is scrolling down
    // we will scroll manually for 1 step; afterwards native scroll events will
    // be detected and will happen normally
    const element = scrollingElement.value
    if (element) element.scrollTop = -distance
    scrollWithPan.value = false
  } else {
    // Most of the time:
    // Move the tray according to mouse drag distance
    dragDistanceY.value = Math.max(dragDistanceYBeforeStart.value + distance, DRAG_DISTANCE_AT_TOP.value)
    updateElement() // really update the element to move
    latestVelocityY.value = velocity
    latestPanEventTimestamp.value = timestamp
    /**
     * Dragging event, emit the distance dragged
     */
    emit('dragging', distance)
  }
}

const handlePanEnd = (velocity: number, timestamp: number): void => {
  const endVelocity = computeEndVelocity({
    endEvent: { velocity, timestamp },
    lastPanEvent: { velocity: latestVelocityY.value, timestamp: latestPanEventTimestamp.value },
  })
  if (dragDistanceY.value <= DRAG_DISTANCE_AT_TOP.value) {
    dragDistanceY.value = DRAG_DISTANCE_AT_TOP.value
    enableScroll()
  }

  const isAtTopBeforeDragging = dragDistanceYBeforeStart.value <= DRAG_DISTANCE_AT_TOP.value

  if (endVelocity > 300) {
    emit('swipedown', { isAtTopBeforeDragging })
    if (latestVelocityY.value + velocity > 0) resetElement('bottom', 'fast')
    else resetElement('top', 'fast')
  } else if (endVelocity < -300) {
    // Is swiping, so we scroll to the top/bottom
    emit('swipeup', { isAtTopBeforeDragging })
    if (latestVelocityY.value + velocity < 0) resetElement('top', 'fast')
    else resetElement('bottom', 'fast')
  } else {
    // Not swiping, so we detect the end location and decide to scroll to top/bottom
    if (dragDistanceY.value > DRAG_DISTANCE_AT_TOP.value / 2) {
      resetElement('bottom', 'slow')
    } else if (
      DRAG_DISTANCE_AT_TOP.value / 2 > dragDistanceY.value &&
      dragDistanceY.value > DRAG_DISTANCE_AT_TOP.value
    ) {
      resetElement('top', 'slow')
    }
  }

  /**
   * Drag end event, can be used to re-enable interaction to items in content after disabling in dragstart
   */
  emit('dragend', dragDistanceY.value)
}

const addPanListener = (): void => {
  if (props.fullSize) return
  if (props.disableDrag) {
    enableScroll()
    return
  }
  if (!hammer && modalContainerInner.value) {
    hammer = new Hammer(modalContainerInner.value)
  }
  hammer.get('pan').set({ direction: Hammer.DIRECTION_VERTICAL })
  hammer.on('panstart', (_e) => {
    handleStartPan()
  })

  hammer.on('panmove', (e) => {
    handlePanMove(e.deltaY, e.velocityY, e.timeStamp)
  })

  hammer.on('panend', (e) => {
    handlePanEnd(e.velocityY, e.timeStamp)
  })
  panEnabled.value = true
}

const removePanListener = (): void => {
  if (!hammer) return
  hammer.off('panstart panmove panend')
  panEnabled.value = false
}

/**
 * WATCHERS
 * */

watch(
  () => props.disableDrag,
  (disabled: boolean): void => {
    if (disabled) {
      removePanListener()
    } else {
      addPanListener()
    }
  },
)

watch(
  () => props.fullSize,
  (fullSize: boolean): void => {
    // For mobile, we simulate a drag with an animation.
    if (fullSize) {
      resetElement('full', 'slow')

      setTimeout(() => {
        // reset scrollTop for iOS
        // when keyboard pops up, <html> will scroll down a bit
        document.documentElement.scrollTop = 0
      }, 100)
      removePanListener()
    } else {
      resetElement('top', 'slow')
      addPanListener()
    }
  },
)

watch(
  () => props.preferredTrayStartHeight,
  (): void => {
    nextTick(() => {
      computePrecalculatedMeasurement()
      setStartingTopMargin()
    })
  },
)

/**
 * COMPONENTS LIFECYCLE
 * */

/**
 * When the component mount, with the device information we calculate the measurements
 * necessary for the bottom tray to be work beautifully
 */
const computePrecalculatedMeasurement = (): void => {
  MAX_TOP_MARGIN.value = props.preferredTrayStartHeight
    ? props.windowHeight - props.preferredTrayStartHeight
    : props.windowHeight / 4 // Leave a maximum of windowHeight/4 px gap at the top for when the drawer is not expanded
  MIN_TOP_MARGIN.value = ((): number => {
    if (props.shouldFullSizeCoverScreen) return props.minTopMargin
    const scrollingContent = modalBodyContent.value
    if (scrollingContent) {
      const min = props.windowHeight - scrollingContent.clientHeight - (header.value?.clientHeight || 0)
      if (min > MAX_TOP_MARGIN.value) return props.minTopMargin
      if (min < 0) return props.minTopMargin
      return min
    }
    return props.minTopMargin
  })()
  DRAG_DISTANCE_AT_TOP.value = -MAX_TOP_MARGIN.value + Math.max(0, MIN_TOP_MARGIN.value)
}

/**
 * For small devices, we start with the bottom tray slided down using a top margin
 */
const setStartingTopMargin = (): void => {
  marginTop.value = MAX_TOP_MARGIN.value
  if (props.fullSize || draggedToFullSize.value) dragDistanceY.value = DRAG_DISTANCE_AT_TOP.value
  updateElement()
}

onMounted((): void => {
  computePrecalculatedMeasurement()
  setStartingTopMargin()
  addPanListener()
})

onBeforeUnmount((): void => {
  removePanListener()
  hammer?.destroy()
})
</script>

<script lang="ts">
export default {}
</script>

<template>
  <transition leave-active-class="out">
    <OzOverlay
      :class="[
        'overflow-hidden',
        // Push content to bottom of the screen
        'flex',
        'flex-col',
        'justify-end',
        'items-center',
        // Can remove this once Oz page reset has been applied to all pages.
        'font-sans',
      ]"
      :style="{
        paddingTop: 'var(--safe-area-inset-top)',
      }"
      :is-spectral="isScrimSpectral"
      :scrim="xScrim ? 'modal' : null"
      :should-fade-in="shouldFadeIn"
      :z-index-class="zIndexClass"
      :dark-mode="darkMode"
      :position="position"
      @scrim-click="$emit('scrim-click')"
      @scrim-esc="$emit('scrim-esc')"
    >
      <div
        ref="modalContainer"
        :class="[
          {
            'mt-96 min-h-auto w-full': true,
            'h-full': shouldFullSizeCoverScreen,
            'overflow-auto flex flex-col': true,
            'no-scrollbar': hideScrollbar,
            'modal-container': true,
            'shadow-elevation-5': !fullSize && !draggedToFullSize,
            'rounded-none': (fullSize || draggedToFullSize) && MIN_TOP_MARGIN === minTopMargin,
            'rounded-3xl rounded-b-none': (!fullSize && !draggedToFullSize) || MIN_TOP_MARGIN > minTopMargin,
            'full-size': fullSize || draggedToFullSize,
            'bg-light-ui-100 dark:bg-dark-ui-100': darkMode === 'auto',
            'bg-light-ui-100 ': darkMode === false,
            'bg-dark-ui-100': darkMode === true,
            'pb-safe-inset-bottom': true,
          },
        ]"
      >
        <div
          ref="modalContainerInner"
          :class="[
            {
              'bg-light-ui-100 dark:bg-dark-ui-100': darkMode === 'auto',
              'bg-light-ui-100 ': darkMode === false,
              'bg-dark-ui-100': darkMode === true,
              'min-h-full flex grow flex-col overflow-hidden': true,
            },
          ]"
        >
          <div ref="modalContainerInnerWrapper" :class="[isScrollingWholeModal ? 'block' : 'contents', 'pb-0']">
            <header v-if="xHeader" ref="header">
              <div
                v-if="xDrawerHandle"
                id="tray-expander-controller"
                class="flex justify-center mb-2 pt-1"
                data-testid="draggableDrawerHandle"
              >
                <div class="rounded w-7 h-1 bg-highway-disabled dark:bg-white-disabled" />
              </div>
              <slot name="header" />
            </header>
            <div
              ref="modalBody"
              :class="{
                'h-auto flex grow flex-col p-0 overflow-hidden': true,
                'overflow-y-auto': fullSize || draggedToFullSize,
              }"
              @touchstart="handleTrayScrollStart"
              @touchmove="handleTrayScroll"
              @touchend="$emit('dragend', dragDistanceY)"
            >
              <div ref="modalBodyContent">
                <slot name="body" />
              </div>

              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </OzOverlay>
  </transition>
</template>

<style lang="scss" scoped>
@import '@@/styles/3/modules/responsive';
@import '@@/styles/3/modules/all';

.modal-container {
  @apply max-h-none;

  animation-name: modal-slide-up; // Slide up the modal when showing
  animation-duration: 0.2s;
  // Being an incoming elements and is animated using decelerated easing
  // @see https://material.io/design/motion/speed.html#controlling-speed
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);

  &.full-size {
    animation-duration: 0.4s;
  }

  .out & {
    animation-name: modal-slide-down; // Slide up the modal when showing
    animation-duration: 0.15s;
    // Being an incoming elements and is animated using accelerated easing
    // @see https://material.io/design/motion/speed.html#controlling-speed
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    animation-fill-mode: forwards;
  }
}

@keyframes modal-slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes modal-slide-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}
</style>
